:root{
    --primary: #132439;
    --light_grey: #F2F2F2;
    --grey: #808080;
    --grey2: #DDDDDD;
    --logoHeight: auto;
    --logoWidth: 250px;
    --navy: #222;
    --light_navy: #008e36;
		--black1: rgba(0, 0, 0, 0.87);
		--white:#FFFFFF;
    --black2: #393939;
		--skyblue:#4FAADB;
    --red:#E04D43;
    --yellow:#FCF200;
    --green:#76D33E;
    --grad: ##008e36;
    --grad1: #008e36;
    --grad2: #008e36;
        /*TAKE SAME COLOR AS LIGHT_NAVY AND CONVERT https://www.rapidtables.com/convert/color/hex-to-rgb.html */
    --shadowColor: 79, 170, 219;

    --logoHeight: auto;
    --logoWidth: 250px;

    /*TAKE SAME COLOR AS PRIMARY AND CONVERT https://angel-rs.github.io/css-color-filter-generator/ */
    --primaryfilter: brightness(0) saturate(100%) invert(11%) sepia(7%) saturate(5769%) hue-rotate(176deg) brightness(92%) contrast(92%);
}
