html, body {
  margin: 0;
  margin-bottom: 0;
  background: #f1f1f1;
  height: 100%;
  overflow: hidden;
  /* touch-action: none; */
  /* white-space: pre-line; */
}

#root{
  height: 100%;
}

/*REMOVING GOOGLE BORDER */
.gm-style iframe + div { 
  border:none!important; 
}

.text-area-boy{
  max-height: 200px;
}

.byrger{
  z-index: 9999999;
}
.topbar{
  z-index: 100;
}

.glossy{
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.5); }

.behind{
  z-index: 0;
}

.size_15{
  width: 15px;
  height: 15px;
}
.nav-item-mobile{
  text-decoration: none;
}

.hamburger-container{
  height: 100dvh;
  width: 65dvw;
  background-color: var(--primary);
}

.dvh-100{
  height: 100dvh;
}

.lang-btn{
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

select.form-control{
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAUCAMAAACtdX32AAAAdVBMVEUAAAD///8AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAhMdQaAAAAJ3RSTlMAAAECAwQGBwsOFBwkJTg5RUZ4eYCHkJefpaytrsXGy8zW3+Do8vNn0bsyAAAAYElEQVR42tXROwJDQAAA0Ymw1p9kiT+L5P5HVEi3qJn2lcPjtIuzUIJ/rhIGy762N3XaThqMN1ZPALsZPEzG1x8LrFL77DHBnEMxBewz0fJ6LyFHTPL7xhwzWYrJ9z22AqmQBV757MHfAAAAAElFTkSuQmCC);
  background-position: 100%;
  background-repeat: no-repeat;
}

.sidebar-inner{
  background-color: var(--navy);
}
.filler{
  width: 100%;
  height: -webkit-fill-available;
}

.active-bg-gradient-grey::before{
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  height: 4px;
  border-bottom-right-radius: 10px;
  background-color: var(--navy);
}

.active-bg-gradient-grey::after{
  content: "";
  position: absolute;
  bottom: 0px;
  right: 0px;
  left: 0px;
  height: 4px;
  border-top-right-radius: 10px;
  background-color: var(--navy);
  }


.sidebar {
    position: fixed;
    display: flex;
    flex-direction: column;
    z-index: 99;
    width: 60px;
    /* background-color: var(--navy); */
  /*  background-color: var(--navy); */
    margin-left: 0px;
    margin-top: 0px;
    padding: 0px;
    align-items: center;
    justify-items: center;
    text-align: center;
    filter: drop-shadow(2px 0px 20px rgba(0, 0, 0, 0.12));
}

.scrollable-y {
  overflow-y: auto!important;
  overflow-x: hidden;
}

.section-data {
    position: relative;
    height: 100dvh!important;
    height: 100vh;
    margin: auto;
    padding: 0px;
    margin-left: 4rem;
}
@media only screen and (max-width: 768px) {
  .section-data {
    height: 100dvh!important;
    margin-left: 0rem;
  }
}

.section-left_data {
    position: relative;
    height: 100vh;
    padding: 0px;
    padding-left: 20px;
    padding-right: 10px;
}

.section-left_bottom_data {
  width: 32em;
  max-width: 32em;
  right: 0em;
  flex: none !important;
  padding: 0 15px 0 15px;
  height: 100%;
  transition: 0.5s;
  left: 0;
  position: absolute;
}

@media only screen and (max-width: 768px) {
  .section-left_bottom_data {
    width: 100%;
    max-width: 100%;
    height: 60px;
    flex: none !important;
    padding: 0 15px 0 15px;
    transition: 0.5s;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    left: 0;
    bottom: 0;
    position: absolute;
    z-index: 99;
    background-image: linear-gradient(var(--white), var(--light_grey));
    box-shadow: 0px -12px 3px 0px rgba(0, 0, 0, 0.00), 0px -8px 3px 0px rgba(0, 0, 0, 0.01), 0px -4px 3px 0px rgba(0, 0, 0, 0.05), 0px -2px 2px 0px rgba(0, 0, 0, 0.09), 0px 0px 1px 0px rgba(0, 0, 0, 0.10), 0px 0px 0px 0px rgba(0, 0, 0, 0.10);
  }
}

.drag-element{
  width: 80px;
  height: 6px;
  border-radius: 4px;
  background-color: var(--grey);
}

.section-left_bottom_data_expanded_burger{
  width: 32em;
  max-width: 32em;
  left: 66dvw;
  flex: none !important;
  padding-top: 3rem!important;
  padding: 0 15px 0 15px;
  height: 100%;
  transition: 0.5s;
  position: absolute;
}

.map-style_expanded_burger{
  height: calc(100% - env(safe-area-inset-bottom));
  width: calc(100% - 30px);
  bottom: -90%;
  left: 66dvw;
  margin-left: 15px;
  right: 15px;
  margin-top: 15px;
  position: fixed;
  border-top: 1px solid var(--grey2);
  box-shadow: 0px 14px 5px rgba(0, 0, 0, 0.01), 0px 8px 5px rgba(0, 0, 0, 0.05), 0px 3px 3px rgba(0, 0, 0, 0.09), 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  -webkit-border-radius: 4px;
  overflow: hidden;
  transition: 0.5s;
}

.section-left_bottom_data_expanded {
  width: 32em;
  max-width: 32em;
  left: -32em;
  flex: none !important;
  padding: 0 15px 0 15px;
  height: 100%;
  transition: 0.5s;
  position: absolute;
}
@media only screen and (max-width: 768px) {
  .section-left_bottom_data_expanded {
    width: 100%;
    max-width: 100%;
    flex: none !important;
    padding: 0 15px 0 15px;
    height: 100%;
    transition: 0.5s;
    left: 0;
    bottom: 70%;
    position: absolute;
  }
}
  

.section-right_data {
    height: 100%;
    text-align: center;
    padding: 0px;
}

.expand_map{
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 99999;
    background-color: var(--grey);
    height: 40px;
    width: 40px;
    border-radius: 0px 0px 40px 0px;
    opacity: 0.4;
    transition: 0.2s;
  }
  
  .expand_map:hover{
    background-color: var(--primary);
    opacity: 1;
    transition: 0.2s;
  }

  .expand_map:active{
    transform: scale(1.2);
    transition: 0.2s;
  }

  .expand_map_icon{
    position: relative;
    top: -3px;
    left: -3px;
  }

  .white-icon {
    filter: brightness(0) invert(1);
  }

  .primary-icon {
    filter: var(--primaryfilter);

  }

.mobile-map{
  padding: 0;
}

.map_style {
  height: calc(100% - 30px - env(safe-area-inset-bottom));
  width: calc(100% - 37em);
  right: 15px;
  margin-top: 15px;
  position: fixed;
  border-top: 1px solid var(--grey2);
  box-shadow: 0px 14px 5px rgba(0, 0, 0, 0.01), 0px 8px 5px rgba(0, 0, 0, 0.05), 0px 3px 3px rgba(0, 0, 0, 0.09), 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  -webkit-border-radius: 4px;
  overflow: hidden;
  transition: 0.5s;
}
@media only screen and (max-width: 768px) {
  .map_style {
    /* height: calc(100% - env(safe-area-inset-bottom)); */
    bottom: 0px;
    left: 0px;
    right: 0px;
    top: 2.4rem;
    height: auto;
    width: auto;
    position: fixed;
    border-top: 1px solid var(--grey2);
    box-shadow: 0px 14px 5px rgba(0, 0, 0, 0.01), 0px 8px 5px rgba(0, 0, 0, 0.05), 0px 3px 3px rgba(0, 0, 0, 0.09), 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
    border-radius: 0px;
    -webkit-border-radius: 0px;
    overflow: hidden;
    transition: 0.5s;
  }
}

.map_style_expanded {
  height: calc(100% - 30px - env(safe-area-inset-bottom));
  width: calc(100% - 5.55em);
  right: 15px;
  margin-top: 15px;
  position: fixed;
  border-top: 1px solid var(--grey2);
  box-shadow: 0px 14px 5px rgba(0, 0, 0, 0.01), 0px 8px 5px rgba(0, 0, 0, 0.05), 0px 3px 3px rgba(0, 0, 0, 0.09), 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  -webkit-border-radius: 4px;
  overflow: hidden;
  transition: 0.5s;
}

@media only screen and (max-width: 768px) {
  .map_style_expanded {
    height: calc(85% - env(safe-area-inset-bottom));
    width: calc(100% - 30px);
    bottom: 15px;
    left: 15px;
    right: 15px;
    margin-top: 15px;
    position: fixed;
    border-top: 1px solid var(--grey2);
    box-shadow: 0px 14px 5px rgba(0, 0, 0, 0.01), 0px 8px 5px rgba(0, 0, 0, 0.05), 0px 3px 3px rgba(0, 0, 0, 0.09), 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    -webkit-border-radius: 4px;
    overflow: hidden;
    transition: 0.5s;
  }
}

.map_style > div {
  border-radius: 4px;
  -webkit-border-radius: 4px;
  overflow: hidden;
}

.title_icon {
    height: 2rem;
    width: 2rem;
}
.title_icon-sm {
    height: 1.5rem;
    width: 1.5rem;
}


.deviceTag {
  position: absolute;
  top: 10%;
  background-color: var(--navy);
  opacity: 0.5;
  padding-left: 17px;
  text-align: center;
  padding-right: 16px;
  width: fit-content;
  width: max-content;
  height: 46px;
  border-radius: 0px 4px 4px 0px;
  pointer-events: none;
  z-index: 9999;
  margin-left: 0px;
  color: #fff!important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.deviceTag::before {
  content: "";
  position: absolute;
  background-color: transparent;
  bottom: 46px;
  left: 0px;
  height: 25px;
  width: 9px;
  border-bottom-left-radius: 25px;
  box-shadow: 0 18px 0 0 var(--navy);
}

.deviceTag::after {
  content: "";
  position: absolute;
  background-color: transparent;
  bottom: -25px;
  left: 0px;
  height: 25px;
  width: 9px;
  border-top-left-radius: 25px;
  box-shadow: 0 -18px 0 0 var(--navy);
}

.nametag_fade_in{
    left: 59px;
    opacity: 1;
    transition: 0.2s;
    transition-delay: 0.5s;
  }
  
  .nametag_fade_out{
    left: 50px;
    opacity: 0;
    transition: none;
  
  }
  .sidebar-item{
    width: 100%;
    padding-left: 0;
    position: relative;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .sidebar-item > img {
    transition: 0.1s;
  }

  .nav-logo {
    transition: 0.1s;
  }

  .nav-logo:hover {
    filter: brightness(1.1);
    transform: scale(1.1);
    transition: 0.1s;
  }

  .sidebar-item:hover > img {
    transform: scale(1.1);
    transition: 0.1s;
  }

  .sidebar-item:active > img {
    transform: scale(0.9);
    transition: 0.1s;
  }

  .active-bg-gradient-grey{
    opacity: 1;
    background: transparent;
  }


  .search_bar_new{
    display: flex;
    height: 35px;
    width: auto;
    background: #FFFFFF;
    box-shadow: 0px 14px 5px rgba(0, 0, 0, 0.01), 0px 8px 5px rgba(0, 0, 0, 0.05), 0px 3px 3px rgba(0, 0, 0, 0.09), 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    padding-right: 5px;
    transition-duration: 0.3s;
}



  .search_bar{
    display: flex;
    height: 35px;
    width: 300px;
    background: #FFFFFF;
    box-shadow: 0px 14px 5px rgba(0, 0, 0, 0.01), 0px 8px 5px rgba(0, 0, 0, 0.05), 0px 3px 3px rgba(0, 0, 0, 0.09), 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    padding-right: 5px;
    transition-duration: 0.3s;
}

@media only screen and (max-width: 768px) {
  .search_bar{
    width: auto;
  }
}

.search_bar input{
  color: var(--primary);
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 16px;
  justify-content: center;
  align-items: center;
  justify-self: center;
  margin: 0px;
  padding: 0px;
  margin-left: 7px;
  border: #FFFFFF;
}

.search_bar input:focus {
  box-shadow: none;
}

.search_bar_new input{
  color: var(--primary);
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 16px;
  justify-content: center;
  align-items: center;
  justify-self: center;
  margin: 0px;
  padding: 0px;
  margin-left: 7px;
  border: #FFFFFF;
}

.search_bar input:focus {
  box-shadow: none;
}

.cursor-pointer {
  cursor: pointer !important;
}

.list-group-item {
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, .125);
}


.size_20 {
  width: 20px;
  min-width: 20px;
  height: 20px;
  min-height: 20px;
}

.size_25 {
  width: 25px;
  min-width: 25px;
  height: 25px;
  min-height: 25px;
}


.button_icon {
  height: 11px;
  width: 11px;
}

.bg-neverconnected{
  background-color: var(--grey)
}
.bg-offline{
  background-color: var(--red);
}

.bg-online {
  background-color: var(--green);
}

.bg-white {
  background-color: var(--white) !important;
}

.bg-primary {
  background-color: var(--primary);
}

.bg-dark-grey{
  background: var(--grey);
}

.bg-checked{
  background-color: var(--green);
}

.bg-grey{
  background: var(--grey2);
}

.bg-light-grey{
  background: var(--light_grey);
}

.share_device_list{
  max-height: 25vh;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  transition: 0.3s; 
}
@media only screen and (max-width: 768px) {
  .share_device_list{
    max-height: 25vh;
  }
}


.settings_device_list{
  max-height: 25vh;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  transition: 0.3s; 
}
@media only screen and (max-width: 768px) {
  .settings_device_list{
    max-height: 100%;
  }
}


.safety_list{
  max-height: calc(100vh - 9.5rem - env(safe-area-inset-bottom));
  -webkit-border-radius: 4px;
  border-radius: 4px;
  transition: 0.3s; 
}
@media only screen and (max-width: 768px) {
  .safety_list{
    max-height: calc(100vh - 20.5rem - env(safe-area-inset-bottom));
  }
}

.geofence_list{
  max-height: calc(100vh - 15.9rem - env(safe-area-inset-bottom));
  -webkit-border-radius: 4px;
  border-radius: 4px;
  transition: 0.3s; 
}

.geofence_list.pushed{
  max-height: calc(100vh - 19.1rem - env(safe-area-inset-bottom));
  transition: 0.3s;
}
@media only screen and (max-width: 768px) {
  .geofence_list{
    max-height: calc(100vh - 29.5rem - env(safe-area-inset-bottom));
  }
  .geofence_list.pushed{
    max-height: calc(100vh - 32.6rem - env(safe-area-inset-bottom));
  }
}


.alarm_list{
  max-height: calc(100vh - 12.65rem - env(safe-area-inset-bottom));
  -webkit-border-radius: 4px;
  border-radius: 4px;
  transition: 0.3s;
}
.alarm_list.pushed{
  max-height: calc(100vh - 15.9rem - env(safe-area-inset-bottom));
  transition: 0.3s;
}

@media only screen and (max-width: 768px) {
  .alarm_list{
    max-height: calc(100vh - 26.5rem - env(safe-area-inset-bottom));

  }
  .alarm_list.pushed{
    max-height: calc(100vh - 29.2rem - env(safe-area-inset-bottom));
  }
}



.history_device_list{
  max-height: calc(100vh - 18.55rem - env(safe-area-inset-bottom));
  -webkit-border-radius: 4px;
  border-radius: 4px;
  transition: 0.3s; 
}
.history_device_list.pushed{
  max-height: calc(100vh - 21.8rem - env(safe-area-inset-bottom));
  transition: 0.3s;
}
@media only screen and (max-width: 768px) {
  .history_device_list{
    max-height: calc(100vh - 31rem - env(safe-area-inset-bottom));

  }
  .history_device_list.pushed{
    max-height: calc(100vh - 34rem - env(safe-area-inset-bottom));
  }
}

.geofence_device_list{
  max-height: calc(100vh - 21.91rem - env(safe-area-inset-bottom));
  -webkit-border-radius: 4px;
  border-radius: 4px;
  transition: 0.3s;
}
.geofence_device_list.pushed{
  max-height: calc(100vh - 25.1rem - env(safe-area-inset-bottom));
  transition: 0.3s;
}
@media only screen and (max-width: 768px) {
  .geofence_device_list{
    max-height: calc(100vh - 35.5rem - env(safe-area-inset-bottom));

  }
  .geofence_device_list.pushed{
    max-height: calc(100vh - 38.5rem - env(safe-area-inset-bottom));
  }
}

.group_device_list{
  max-height: calc(100vh - 40.15rem - env(safe-area-inset-bottom));
  -webkit-border-radius: 4px;
  border-radius: 4px;
  transition: 0.3s;
}
@media only screen and (max-width: 768px) {
  .group_device_list{
    max-height: calc(100vh - 38.15rem - env(safe-area-inset-bottom));
  }
}

.group_scroll{
  max-height: calc(100vh - 13.15rem - env(safe-area-inset-bottom));
  -webkit-border-radius: 4px;
  border-radius: 4px;
  transition: 0.3s;
}

@media only screen and (max-width: 768px) {
  .group_scroll{
    max-height: calc(100vh - 26.5rem - env(safe-area-inset-bottom));
  }
}

.route_list{
  max-height: calc(100vh - 13rem - env(safe-area-inset-bottom));
  -webkit-border-radius: 4px;
  border-radius: 4px;
  transition: 0.3s; 
}
.route_list.pushed{
  max-height: calc(100vh - 16.25rem - env(safe-area-inset-bottom));
  transition: 0.3s;
}

@media only screen and (max-width: 768px) {
  .route_list{
    max-height: calc(100vh - 26.3rem - env(safe-area-inset-bottom));
    -webkit-border-radius: 4px;
    border-radius: 4px;
    transition: 0.3s; 
  }
  .route_list.pushed{
    max-height: calc(100vh - 29.3rem - env(safe-area-inset-bottom));
    transition: 0.3s;
  }
}

.mass_share_list{
  max-height: calc(100vh - 13.9rem - env(safe-area-inset-bottom));
  -webkit-border-radius: 4px;
  border-radius: 4px;
  transition: 0.3s;
}
.mass_share_list.pushed{
  max-height: calc(100vh - 17.1rem - env(safe-area-inset-bottom));
  transition: 0.3s;
}

@media only screen and (max-width: 768px) {
  .mass_share_list{
    max-height: calc(100vh - 27.8rem - env(safe-area-inset-bottom));
  }
  .mass_share_list.pushed{
    max-height: calc(100vh - 30.8rem - env(safe-area-inset-bottom));
  }
}


.device_list{
  max-height: calc(100vh - 16.8rem - env(safe-area-inset-bottom));
  -webkit-border-radius: 4px;
  border-radius: 4px;
  transition: 0.3s;
}
.device_list.pushed{
  max-height: calc(100vh - 20.54rem - env(safe-area-inset-bottom));
  transition: 0.3s;
}

@media only screen and (max-width: 768px) {
  .device_list{
    max-height: calc(100vh - 30.5rem - env(safe-area-inset-bottom));
  }
  .device_list.pushed{
    max-height: calc(100vh - 33.6rem - env(safe-area-inset-bottom));
  }
}

.receipt_list{
  max-height: calc(100vh - 21.5rem - env(safe-area-inset-bottom));
  -webkit-border-radius: 4px;
  border-radius: 4px;
  transition: 0.3s;
}
.subscription_device_list{
  max-height: calc(100vh - 62vh - env(safe-area-inset-bottom));
  height: auto;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  transition: 0.3s;
}

@media only screen and (max-width: 768px) {
  .subscription_device_list{
    height: calc(100vh - 30.5rem - env(safe-area-inset-bottom));
  }
}



/* custom scrollbar */
::-webkit-scrollbar {
  width: 5px;
  border-radius : 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius : 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius : 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ddd;
  border-radius : 5px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:active {
background: #ccc;
  box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.1);
}
/* custom scrollbar */

.devicemapicon{
  position: absolute;
  right: 8px;
}

.detail_container {
  background-color: #eee;
}

.text-red {
  color: #E04D43;
}

.date_label {
  background-color: var(--grey2);
  color: var(--primary);
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  border-radius: 6px;
  box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.1);
}
.date_label input{
  padding: 6px 0px 5px !important;
  margin-left: 7px;
}

.date_label fieldset {
  border-width: 0px!important;
}

.date_label button{
  padding-left: 0px !important;
}


.add_device_logo {
  height: auto;
  width: auto;
  max-width: 100% !important;
}

.in_app_message_dialog{
  width: 100%;
  min-height: 625px;
  height: 100%;
}

.add_device_dialog {
  height: auto;
  width: 600px;
}
@media only screen and (max-width: 768px) {
  .add_device_dialog {
    width: auto;
  } 
}


.dot_alarm {
  height: 7px;
  width: 7px;
  border-radius: 50%;
  display: inline-block;
}

.dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
}

.dot_layout {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.invalid-feedback {
  position: absolute;
  bottom: auto;
  display: flex;
  color: var(--red);
  margin-left: 0;
  font-size: 12px;
  width: auto;
}

.rotate-90{
  transform: rotate(90deg);
}

.rotate-180{
  transform: rotate(180deg);
}
.rotate-270{
  transform: rotate(270deg);
}


.device_row_map_icon {
  width: 17px;
  height: auto;
}

.settings-radius{
  border-radius: 4px;
  overflow: hidden;
}

.settings-radius-top{
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.settings-radius-bottom{
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.border-settings{
  border-bottom: 1px solid #dee2e6!important;
}

.border-settings-top{
  border-top: 1px solid #dee2e6!important;

}

.section-divider{
  border-right: 1px solid #dee2e6!important;
}

.vertical-divider-dark{
  border-right: 1px solid var(--grey)!important;
}

.settings-right-section{
  margin-top: 30px;
  padding: 0 15px 0 15px;
}


.form-switch .form-check-input {
	width: 2.5em;
	height: 1.5em;
	background-repeat: no-repeat;
	box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.1);
}

.form-check-input {
  width: 3em;
	height: 1.5em;
	border-color: red !important;
	/*background-color: var(--btn-secondary-color)!important;*/
	background-color: var(--light_grey) !important;
	box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.1);
	border: 0px !important;
}

.form-check-input:checked {
  background-color: var(--primary)!important;
  border-color: var(--primary-color)!important;
}

.subscription-radio{
  width: 1em;
	height: 1em;
	border-color: red !important;
	/*background-color: var(--btn-secondary-color)!important;*/
	background-color: var(--light_grey) !important;
	box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.1);
	border: 0px !important;
}



.slider_x {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto; /* Ensure it's auto to show scrollbar as needed */
  padding-bottom: 5px;
  overflow-y: hidden;
  max-width: 100%; /* Prevents it from exceeding the parent container */
}

.notification-scroll ::-webkit-scrollbar {
  width: 0.3rem;
  height: 0.3rem;
  }

  .search_capsule {
    display: flex;
    height: 25px;
	/* width: 80px; */
    background: var(--navy);
    border-radius: 0px;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    white-space: nowrap;
    padding-left: 15px;
    padding-right: 15px;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
	/* padding-right: 10px; */
	/* flex-direction: row; */
	/* flex-shrink: 1; */
}

.search_capsule label {
  color: #FFFFFF;
  font-family: 'Roboto';
  font-style: normal;
/* width: 60px; */
  font-weight: 700;
  font-size: 11px;
  line-height: 13px;
/* margin-left: 5px; */
/* margin-right: 3px; */
  margin-bottom: 0px;
/* padding: 0px; */
  text-transform: capitalize;
  flex-shrink: 1;
}


.name_capsule {
  color: #FFFFFF;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 13px;
  margin-left: 5px;
  margin-right: 3px;
  margin-bottom: 0px;
  padding: 0px;
  text-transform: capitalize;

  height: 25px;

  background: var(--navy);

  border-radius: 12px;
  justify-content: center;
  align-items: center;
}

.faq-collapse-shadow{
  box-shadow: 0px 14px 5px rgba(0, 0, 0, 0.01), 0px 8px 5px rgba(0, 0, 0, 0.05), 0px 3px 3px rgba(0, 0, 0, 0.09), 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);

}

.contact-button{
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.settings-dialog {
  height: 100%;
  width: 400px;
}

@media only screen and (max-width: 768px) {
  .settings-dialog {
    width: 100%; /* Adjust 32px according to your MUI Dialog padding */  }
}

.round-image{
    border-radius: 200px;
    border: 2px solid;
    background-color: var(--primary);
    padding: 10px;
    width: 60px;
    height: 60px;
}

.btn:focus,button:focus {
  outline: none !important;
  /* background: var(--grey2) !important;
  box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.1 ) !important; */

  /*box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.0 ) !important;
  box-shadow: 0 14px 5px rgba(0,0,0,.01),0 8px 5px rgba(0,0,0,.05),0 3px 3px rgba(0,0,0,.09),0 1px 2px rgba(0,0,0,.1),0 0 0 rgba(0,0,0,.1)!important;*/

}

.btn{
  transition-duration: 0.1s !important;
  border: none;
  height: 35px;
}

.btn:active {
  transition-duration: 0.1s !important;
    background: var(--grey2) !important;
    box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.1) !important;
    
}

.btn-primary{
  background-color: var(--navy);
  border-color: var(--navy);
}

.btn-primary:hover{
  background-color: var(--light_navy);
}

.btn-primary.disabled, .btn-primary:disabled{
  background-color: var(--light_navy);
  border-color: var(--light_navy);

}

.bg-light-navy{
  background-color: var(--light_navy)!important;
}

.bg-primary{
  background-color: var(--navy)!important;
  color: #fff;
}

.btn-grey{
  background-color: var(--grey2);
  border: none;
  color: var(--navy);
  box-shadow: 0 14px 5px rgba(0,0,0,.01),0 8px 5px rgba(0,0,0,.05),0 3px 3px rgba(0,0,0,.09),0 1px 2px rgba(0,0,0,.1),0 0 0 rgba(0,0,0,.1); 
}

.btn-secondary{
  background-color: var(--grey2);
  border: none;
  color: var(--grey);
}

.btn-secondary:hover{
  background-color: var(--grey2);
  border: none;
  color: var(--navy);
}

.btn-left-section{
  background-color: #fff;
  border: none;
  color: var(--navy);
  box-shadow: 0 14px 5px rgba(0,0,0,.01),0 8px 5px rgba(0,0,0,.05),0 3px 3px rgba(0,0,0,.09),0 1px 2px rgba(0,0,0,.1),0 0 0 rgba(0,0,0,.1);
}

.btn-left-section{
  background-color: #fff;
  border: none;
  color: var(--navy);
}

.btn-selected{
  background-color: var(--light_navy);
  border: none;
  color: var(--white);
  box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.1) !important;

}

img[src*="static/media/close_gray.87d680f41b4e0bbc1a02ef16e879a32f.svg"]{
  transition: 0.2s;

  }

  img[src*="static/media/close_gray.87d680f41b4e0bbc1a02ef16e879a32f.svg"]:hover {
  transition: 0.2s;
  filter: invert(0.05);

  }

  img[src*="static/media/close_gray.87d680f41b4e0bbc1a02ef16e879a32f.svg"]:active {
  transition: 0.2s;
  filter: invert(0.2);

}

.search_bar:focus-within ,.dataplane_search:focus-within {
  transition-duration: 0.3s;
  box-shadow: 0px 51px 20px rgba(0, 0, 0, 0.01), 0px 28px 17px rgba(0, 0, 0, 0.05), 0px 13px 13px rgba(0, 0, 0, 0.09), 0px 3px 7px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
}

.search_bar_sort_icon{
  transition-duration: 0.2s;
  }

.search_bar_sort_icon:hover {
transition-duration: 0.2s;
filter: brightness(0) saturate(100%) invert(19%) sepia(23%) saturate(735%) hue-rotate(194deg) brightness(98%) contrast(89%);
}

.language_popup{
  transform: scale(0);
  position: fixed;
  bottom: 60px;
  left: 60px;
  z-index: 1030;
  transition: 0.2s;
  transform-origin: bottom left; /* Sets the origin of transformation */
}

@media only screen and (max-width: 768px) {
  .language_popup{
    transform: scale(0);
    position: fixed;
    bottom: 40px;
    left: 70px;
    z-index: 1030;
    transition: 0.2s;
    transform-origin: bottom left; /* Sets the origin of transformation */
  }
}

.lang_show{
  transform: scale(1);
  transition: 0.2s;
  transform-origin: bottom left; /* Sets the origin of transformation */
}

.lang_popup_window{
  background-color: var(--light_grey);
  border-radius: 4px;
  max-width: 160px;
  box-shadow: 0 51px 20px rgba(0,0,0,.01),0 28px 17px rgba(0,0,0,.05),0 13px 13px rgba(0,0,0,.09),0 3px 7px rgba(0,0,0,.1),0 0 0 rgba(0,0,0,.1);
}

.lang_pill{
  text-align: center;
  font-weight: 700;
  align-self: center;
  background-color: #ddd;
  border-width: 0;
  border-radius: 4px;
  color: var(--navy);
  transition: .2s;
  box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
  width: 40px;
}

.lang_pill:hover{
  color: #fff;
  background-color: var(--navy);
  transition: .2s;
}

.lang_selected{
  color: #fff;
  background-color: var(--navy);
  transition: .2s;
}

.login_logo{
  max-height: var(--logoHeight);
  width: var(--logoWidth);
}

.form-floating>.form-control, .form-floating>.form-select{
  height: calc(2.8rem + 2px);
}

.login-input-field{
  background-color: transparent;
  border: none;
  border-bottom: 1px solid var(--navy)!important;
  border-radius: 0;
  color: #000;
  margin-left: 0;
  padding-left: 0!important;
  padding-bottom: 2px!important;
}

.login-input-field:focus{
  background-color: transparent;
  border: none;
  border-bottom: 1px solid var(--light_navy)!important;
  border-radius: 0;
  color: #000;
  margin-left: 0;
  padding-left: 0;
  border-color: none;
  outline: 0;
  box-shadow: none;
  padding-bottom: 2px!important;
}

.form-floating>label{
  padding-left: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
  transition: background-color 5000s ease-in-out 0s;
}

.form-control{
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.10) inset;
  border: none;
}


.form-control.input-search-new{
  max-width: 0;
  transition: max-width 1s ease-in-out;
}

.form-control.input-search-new.visible{
  max-width: 100%; /* Or a fixed value, e.g., 500px */
  transition: max-width 1s ease-in-out;
}



.form-control.input-search{
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0) inset;
}

.form-control.login-input-field{
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0) inset;
}

.form-control.login-input-field:focus{
  color: var(--primary);
  background-color: transparent;
  border-color: none;
  outline: 0;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0) inset;}

.form-control.input-search:focus{
  color: var(--primary);
  background-color: transparent;
  border-color: none;
  outline: 0;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0) inset;
}

.form-floating>.form-control:focus~label, .form-floating>.form-control:not(:placeholder-shown)~label, .form-floating>.form-select~label {
  opacity: .65;
  transform: scale(.85) translateY(-.75rem) translateX(-.1rem);
  }

.form-control:focus{
  color: var(--primary);
  background-color: var(--grey2);
  border-color: none;
  outline: 0;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.10) inset;
}

.icon-container{
  min-height: 75px;
  min-width: 75px;
}

.scale-icon {
  width: 65px!important;
  height: 65px!important;
  transition: all 0.1s;
}

.size_45 {
  transition: all 0.1s;
  width: 45px;
  height: 45px;
}

.underline{
  text-decoration: underline;
}

.activate-step-counter {
  width: 30px; /* Adjust the width and height according to your design */
  height: 30px;
  border-radius: 50%; /* This creates a round shape */
  color: white; /* Adjust the text color as needed */
  display: flex;
  background: var(--grey2); /* Default background color */
  align-items: center;
  justify-content: center;
}

.activate-step-counter.current {
  background: linear-gradient(135deg, var(--grad1) 0%, var(--grad2) 100%);
}

.activate-step-counter.done {
  background-color: var(--navy); /* Background color for completed steps */
}

.activate-step-divider {
  width: 80px; /* Adjust the width of the line */
  height: 0; /* Set height to 0 to ensure it's a line, not a rectangle */
  border-bottom: 2px solid var(--grey2); /* Adjust thickness and color as needed */
  margin: 0 auto; /* Center the line horizontally */
}

.activate-step-divider.done {
  border-bottom: 2px solid var(--light_navy); /* Adjust thickness and color as needed */
}

.border-20{
  border-radius: 20px;
}

.border-12{
  border-radius: 12px;
}

.border-sm{
  border-radius: 4px;
}

.gradient-button-primary {
  border: none;
  background: linear-gradient(135deg, var(--grad1) 0%, var(--grad2) 100%);
  transition: 0.1s;
}

.gradient-button-primary:hover {
  border: none;
  background: linear-gradient(75deg, var(--grad1) 0%, var(--grad2) 100%);
  transition: 0.1s;
}

.centered_line{
  width: 100%;
  height: 2px; /* Height of the line */
  border-radius: 1px;
  background-color: var(--grey2); /* Color of the line */
}

.stripe-input {
  background-color: var(--light_grey) !important;
  box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.1);
}

.card-element-inp{
  padding-top: 13px!important;
  height: 40px;
}

.grey-bg{
  border: none;
  background-color: var(--light_grey) !important;
}

.shadow-small {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px 0px rgba(0, 0, 0, 0.10), 0px 3px 3px 0px rgba(0, 0, 0, 0.09), 0px 8px 5px 0px rgba(0, 0, 0, 0.05), 0px 14px 5px 0px rgba(0, 0, 0, 0.01), 0px 21px 6px 0px rgba(0, 0, 0, 0.00) !important;
}

.shadow-medium {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 3px 7px 0px rgba(0, 0, 0, 0.10), 0px 13px 13px 0px rgba(0, 0, 0, 0.09), 0px 28px 17px 0px rgba(0, 0, 0, 0.05), 0px 51px 20px 0px rgba(0, 0, 0, 0.01), 0px 79px 22px 0px rgba(0, 0, 0, 0.00) !important;
}

.shadow-large {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.15), 0px 14px 32px 0px rgba(0, 0, 0, 0.15), 0px 58px 58px 0px rgba(0, 0, 0, 0.13), 0px 130px 78px 0px rgba(0, 0, 0, 0.08), 0px 231px 92px 0px rgba(0, 0, 0, 0.02), 0px 360px 101px 0px rgba(0, 0, 0, 0.00) !important;
}

.shadow-inner{
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.10) inset;
}

.dataplan-card-width{
  width: 25dvw;
  min-width: 275px;
  max-width: 350px;
}

.bestvalue{
  top: -15px;
  left: 60%;
}

@media only screen and (max-width: 768px) {
  .bestvalue{
    top: -5px;
    left: 0%;
  }
}

.subscription_device_list::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
}

.subscription_device_list::-webkit-scrollbar-track {
  background-color: transparent; /* Background color of the scrollbar track */
  margin: 10px; /* Move the scrollbar track to the right by 5 pixels */

}

.MuiCollapse-root#byrger{
  position: absolute;
  top: 0;
  z-index: 100;
  transition: 0.5s;
}

.map-settings-popup{
  max-width: 0px;
  transition: 0.4s;
}

.map-settings-popup-show{
  max-width: 250px;
  transition: 0.3s;
}

.map-settings-popup-title{
  opacity: 0;
  max-width: 0px;
  max-height: 0px;
  transition: 0.1s;
  text-overflow: ellipsis;
  text-wrap: nowrap;

}

.map-settings-popup-title-show{
  opacity: 1;
  max-width: 250px;
  max-height: 50px;
  transition: 0.4s;
  text-overflow: ellipsis;
  text-wrap: nowrap;
}

.map-settings-show{
  /* transform: scale(1);
  transition: 0.5s;
  transform-origin: bottom right; */
}
.map-settings-window{
  border-radius: 4px;
  min-width: 250px;
  max-width: 500px;
}

.map_settings_not_selected{
  border-radius: 4px;
  background-color: var(--grey2);
}

.map_settings_selected{
  border-radius: 4px;
  background-color: var(--primary);
}

.map_type_selected{
  border-radius: 4px;
  border: 2px solid var(--light_navy);
}

.map_type_not_selected{
  border-radius: 4px;
}

.icon_light{
  filter: invert(94%) sepia(100%) saturate(0%) hue-rotate(243deg) brightness(107%) contrast(105%);
}

.page_header{
  display: block;
}

@media only screen and (max-width: 768px) {
  .page_header{
    display: none!important;
  }
}

.mapicon.blue{
  background: linear-gradient(167.96deg,#04BEFE,#4481EB);
}
.mapicon.dark{
  background: linear-gradient(167.96deg,#2A4050,#00111D);
}
.mapicon.green{
  background: linear-gradient(167.96deg,#A9DA1F,#63CD10);
}
/* .mapicon.grey{
  background: linear-gradient(167.96deg,#04BEFE,#4481EB);
} */
.mapicon.light{
  background: linear-gradient(167.96deg,#F9F2F3,#EAF2FF);
}
.mapicon.orange{
  background: linear-gradient(167.96deg,#EED028,#FFA84B);
}
.mapicon.yellow{
  background: linear-gradient(167.96deg,#FBF048,#EED600);
}

.deviceInfoCard{
  /* width: 95px; */
  height: 80px;
}


.selected-item {
  text-align: center;
}

.controls-container {
  display: flex;
  margin: 0px;
}

.controls.device {
  display: inline-flex;
  justify-content: space-between;
  background: var(--light_grey);
  height: 40px;
  box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 2px 2px 2px 2px;
  position: relative;
}

.controls {
  display: inline-flex;
  justify-content: space-between;
  background: var(--grey2);
  height: 40px;
  box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  padding: 2px 2px 2px 2px;
  position: relative;
}

.controls input {
  opacity: 0;
  margin: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;
  cursor: pointer;
  height: 100%;
}


.segment {
  display: flex;
  position: relative;
  text-align: center;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.segment.active.device {
  background: var(--navy);
  border-radius: 8px;
}

.segment.triggered.device {
  background: var(--red);
  border-radius: 8px;
}

.segment.active {
    background: linear-gradient(135deg, var(--grad1) 0%, var(--grad2) 100%);
    border-radius: 30px;
}
.segment.inactive  {
      background: transparent;
      border-radius: 8px;
  }

/* Only allow transitions once component is ready */
.controls.ready::before {
  transition: transform 0.3s ease, width 0.3s ease;
}

.safety-map-border {
  border: 1px solid var(--grey2);
}

.ql-editor img {
  max-width: 100%;
  margin: auto;
  display: block;
  height: auto;
}

.ql-editor h1{
  text-align: center;
}

.ql-editor a{
  text-decoration: none;
  color: var(--light_navy);
}

.quill-content{
  margin-bottom: 100px;
}

.message-window{
  height: 600px;
  padding-bottom: 100px!important;
}

.message-list{
  height: 600px;
}

.eventImg {
  height: 35px;
  width: 35px;
  padding: 0.5rem;
}

.eventImgBg {
  background-color: var(--navy);
  border-radius: 20px;
  height: 35px;
  width: 35px;
}

.eventImgBg-white {
  background-color: white;
  border-radius: 20px;
  height: 35px;
  width: 35px;
}

.message-img-bg{
  width: 35px;
  height: 35px;
  background-color: red;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.event-info-box{
  width: 260px;
}

.device-info-box{
  width: 355px;
}

.transition-slow{
  -webkit-transition:  1000ms;
  -ms-transition:  1000ms;
  transition:  1000ms;
}

.transition-medium{
  -webkit-transition:  300ms;
  -ms-transition:  300ms;
  transition:  300ms;
}

.transition-fast{
  -webkit-transition:  100ms;
  -ms-transition:  100ms;
  transition:  100ms;
}

.overmap {
  position: absolute;
  right: 1rem;
  bottom: calc(1.5rem + env(safe-area-inset-bottom));
  width: auto;
  z-index: 99;
}

@media only screen and (max-width: 768px) {
  .overmap {
    position: absolute;
    right: 1rem;
    bottom: calc(5.5rem + env(safe-area-inset-bottom));
    width: auto;
    z-index: 99;
  }
}

.overmap_2 {
	position: absolute;
    right: 8rem;
    bottom: calc(2rem + env(safe-area-inset-bottom));
	width: auto;
	z-index: 99;
}


.map_settings_box
{
	box-shadow: inset 0px 0px 6px 6px rgba(var(--shadowColor), 50%);
	width: 3rem;
	height: 3.2rem;
	border: 2px solid var(--light_navy);
	border-radius: 10%;
}

.map_settings_icons {
    display: flex;
    flex-direction: column-reverse;
    cursor: pointer;
    align-items: center;
    width: 6rem;
    text-align: center;
}

.map_settings_cog_icon {
    width: 20px;
    height: 20px;
    box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.05);
  }
  
  .map_settings_icon_container {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    align-items: center;
    margin-left: 1rem;
    text-align: center;
  }
  
  .map_settings_icon_box {
    width: 3.5rem;
    padding: 0.3rem 0;
    max-width: 6rem;
    box-shadow: inset 0px 0px 12px 1px #5aade9;
    border-radius: 10%;
    border: 4px solid #5AADE9;
  }
  
  .map_settings_icons {
    display: flex;
    flex-direction: column-reverse;
    cursor: pointer;
    align-items: center;
    width: 6rem;
    text-align: center;
  }
  
  .map_settings_icons_img {
    width: 3rem;
  }
  .map_settings_icons span, .map_settings_icon_container span{
    color: var(--light_grey);
    font-size: 0.9rem;
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    margin-top: 0.3rem;
    margin-bottom: 0.5rem;
    line-height: 1.2;
    text-shadow:
      -1px -1px 0 var(--grey),
       0   -1px 0 var(--grey),
       1px -1px 0 var(--grey),
       1px  0   0 var(--grey),
       1px  1px 0 var(--grey),
       0    1px 0 var(--grey),
      -1px  1px 0 var(--grey),
      -1px  0   0 var(--grey);
  }

  .setting_icon {
    height: 20px;
    width: 20px;
    
  }

  .sidebar-mobile-logo{
    width: 102px;
  }

  .userimage{
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }
  .userimage img {
    width: 100%;
    height: 100%;
    border-radius: 50%; /* Apply the same to ensure the image inside is also rounded */
    object-fit: cover;
  }

  .editimg{
    width: 25px;
    height: 25px;
    background-color: var(--navy);
    border-radius: 50%;
  }

  .editimg img {
    width: 15px;
    height: 15px;
    border-radius: 0%; /* Apply the same to ensure the image inside is also rounded */

  }

  .sort-event-list{
    max-height: 25vh;
    height: auto;
    overflow-y: scroll;
  }

  @media only screen and (max-width: 768px) {
    .sort-event-list{
      max-height: 15vh;
      height: auto;
      overflow-y: scroll;
    }
  }

  .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root:hover{
    background-color: rgba(0,0,0,0);
  }

  .loginfield{
    margin-top: 10%;
  }

/* MUI PISSKLASSER */
  .css-1u23akw-MuiButtonBase-root-MuiPickersDay-root.Mui-selected{
    background-color: var(--navy)!important;
  }
  .css-jgls56-MuiButtonBase-root-MuiPickersDay-root.Mui-selected{
    background-color: var(--navy)!important;
  }
  .css-1e3wlyl-MuiButtonBase-root-MuiMenuItem-root-MuiMultiSectionDigitalClockSection-item.Mui-selected{
    background-color: var(--navy)!important;
  }

  .css-1e6y48t-MuiButtonBase-root-MuiButton-root{
    color: var(--navy)!important;
  }

  .sortCollapse{
    max-height: 75vh;
    overflow-y: auto!important;
  }

  p::after {
    display: block;
    content: attr(title);
    font-weight: bold;
    height: 1px;
    color: transparent;
    overflow: hidden;
    visibility: hidden;
  }