/* SIZES */
/* h1, h2, h3, h4, body, p, small */
/* COLORS */
/* primary, secondary, dark_grey, grey, light_grey, red, yellow, green */
/* MODIFIERS */
/* bold, regular, thin, normal, italic, uppercase, lowercase */
h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin-bottom: 0!important;
}
h1 {
    font-family: "Roboto";
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 44.2px */
    letter-spacing: -0.68px;
}

h2 {
    font-family: "Roboto";
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 36.4px */
    letter-spacing: -0.56px;
}

h3 {
    font-family: "Roboto";
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 28.6px */
    letter-spacing: -0.44px;
}

h4 {
    font-family: "Roboto";
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 22.1px */
    letter-spacing: -0.34px;
}

body {
    font-family: "Roboto";
    font-size: 16px;
    font-style: normal;

    -webkit-font-smoothing: antialiased;
}

p {
    font-family: "Roboto";
    font-size: 12px;
    font-style: normal;
    line-height: 140%; /* 16.8px */
    white-space: pre-line;
    
}

small {
    font-family: "Roboto";
    font-size: 10px;
    font-style: normal;
    line-height: 140%; /* 14px */
    letter-spacing: -0.1px;
}

.btn-text {
    font-family: "Roboto";
    font-size: 12px;
    font-style: normal;
    line-height: 140%; /* 16.8px */
    letter-spacing: -0.12px; 
}

.primary {
    color: var(--primary);
}

.secondary {
    color: var(--light_navy);
}

.dark_grey {
    color: var(--grey);
}

.grey {
    color: var(--grey2);
}

.light_grey {
    color: var(--light_grey);
}

.black {
    color: var(--black2);
}

.white {
    color: white;
}

.red {
    color: var(--red);
}

.yellow {
    color: var(--yellow);
}

.green {
    color: var(--green);
}

.gradient {
    color: var(--grad);
    background: -webkit-linear-gradient(var(--grad1), var(--grad2));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.bold {
    font-weight: 700;
}

.regular {
    font-weight: 400;
}

.thin {
    font-weight: 300;
}

.normal {
    font-style: normal;
}

.italic {
    font-style: italic;
}

.normal {
    font-style: normal;
}

.italic {
    font-style: italic;
}

.uppercase {
    text-transform: uppercase;
}

.lowercase {
    text-transform: lowercase;
}

.underline {
    text-decoration: underline;
}